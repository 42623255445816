import { get, post } from './http';

// 任务列表(分页)
export const apiEgpmTaskPage = (p) => get('/api/egpm/dish/task/page', p);
// 权重配置-获取
export const apiEgpmDictWeight = (p) => get('/api/egpm/dish/dict/weight', p);
// 权重配置-保存
export const apiEgpmDictWeightSave = (p) => post('/api/egpm/dish/dict/weight/save', p);
// 创建任务
export const apiEgpmTaskAdd = (p) => post('/api/egpm/dish/task/add', p);
// 字典列表
export const apiEgpmDictCode = (p) => post('/api/egpm/dish/dict/code', p);
// 任务详情
export const apiEgpmTaskDetail = (p) => get('/api/egpm/dish/task/detail', p);
// 删除任务
export const apiEgpmTaskDel = (p) => get('/api/egpm/dish/task/del', p);
// 重新执行
export const apiEgpmTaskAgain = (p) => post('/api/egpm/dish/task/again', p);
// 新执行(增量|全量)
export const apiEgpmTaskAgainInc = (p) => post('/api/egpm/dish/task/again/inc', p);
// 下载列表(分页)
export const apiEgpmDownloadPage = (p) => get('/api/egpm/dish/download/page', p);
// 删除任务-下载
export const apiEgpmDownloadDel = (p) => post('/api/egpm/dish/download/del', p);
// 数据:单期结果列表(分页)
export const apiEgpmTaskResultSinglePage = (p) => post('/api/egpm/dish/task/result/single/page', p);
// 数据:单期结果图表
export const apiEgpmTaskResultSingleStats = (p) => post('/api/egpm/dish/task/result/single/stats', p);
// 数据:单期结果图表-菜品
export const apiEgpmTaskResultSingleStatsDish = (p) => post('/api/egpm/dish/task/result/single/stats/dish', p);
// 数据:结果列表(分页)
export const apiEgpmTaskResultPage = (p) => post('/api/egpm/dish/task/result/page', p);
// 创建下载任务
export const apiEgpmDownloadCreate = (p) => post('/api/egpm/dish/download/create', p);
// 数据:初始化筛选数据
export const apiEgpmTaskResultFilters = (p) => get('/api/egpm/dish/task/result/filters', p);
// 下载日志保存
export const apiEgpmDownloadLogSave = (p) => post('/api/egpm/dish/download/log/save', p);
// 数据:正确性占比统计
export const apiEgpmTaskResultStatsRatio = (p) => post('/api/egpm/dish/task/result/stats/ratio', p);
// 单期图表单菜top3品牌
export const apiEgpmTaskResultTopBrand = (p) => post('/api/egpm/dish/task/result/top/brand', p);
// 单期图表单菜top3门店
export const apiEgpmTaskResultTopShop = (p) => post('/api/egpm/dish/task/result/top/shop', p);
// 单期图表单菜top3品牌-全菜系
export const apiEgpmTaskResultTopBrand2 = (p) => post('/api/egpm/dish/task/result/top/brand2', p);
// 单期图表单菜top3门店-全菜系
export const apiEgpmTaskResultTopShop2 = (p) => post('/api/egpm/dish/task/result/top/shop2', p);