<template>
    <div class="params_container">
        <div class="tit"><SmallTitle tit="数据筛选范围" /></div>
        <el-descriptions border :column="3">
            <el-descriptions-item label="开始批次" label-class-name="desc_label" class-name="desc_content">{{ task.minBatch }}</el-descriptions-item>
            <el-descriptions-item label="结束批次" label-class-name="desc_label" class-name="desc_content">{{ task.maxBatch }}</el-descriptions-item>
            <el-descriptions-item label="计算方式" label-class-name="desc_label" class-name="desc_content">{{ calcMap[task.calc] }}</el-descriptions-item>
            <el-descriptions-item label="比率" label-class-name="desc_label" class-name="desc_content">{{ ratioMap[task.ratio] }}</el-descriptions-item>
            <el-descriptions-item label="最小客单价" label-class-name="desc_label" class-name="desc_content">{{ task.minPrice }}</el-descriptions-item>
            <el-descriptions-item label="最大客单价" label-class-name="desc_label" class-name="desc_content">{{ task.maxPrice }}</el-descriptions-item>
            <el-descriptions-item label="省份" label-class-name="desc_label" class-name="desc_content">{{ task.province?.join("；") }}</el-descriptions-item>
            <el-descriptions-item :span="2" label="城市" label-class-name="desc_label" class-name="desc_content">{{ task.city?.join("；") }}</el-descriptions-item>
            <el-descriptions-item label="餐饮类型" label-class-name="desc_label" class-name="desc_content">{{ task.cuisine1?.join("；") }}</el-descriptions-item>
            <el-descriptions-item label="菜系类型" label-class-name="desc_label" class-name="desc_content">{{ task.cuisine2?.join("；") }}</el-descriptions-item>
            <el-descriptions-item label="菜系" label-class-name="desc_label" class-name="desc_content">{{ task.cuisine3?.join("；") }}</el-descriptions-item>
            <el-descriptions-item label="原料大类" label-class-name="desc_label" class-name="desc_content">{{ task.material1?.join("；") }}</el-descriptions-item>
            <el-descriptions-item label="原料二类" label-class-name="desc_label" class-name="desc_content">{{ task.material2?.join("；") }}</el-descriptions-item>
            <el-descriptions-item label="原料细类" label-class-name="desc_label" class-name="desc_content">{{ task.material3?.join("；") }}</el-descriptions-item>
            <el-descriptions-item label="风味大类" label-class-name="desc_label" class-name="desc_content">{{ task.flavor1?.join("；") }}</el-descriptions-item>
            <el-descriptions-item :span="2" label="风味二级" label-class-name="desc_label" class-name="desc_content">{{ task.flavor2?.join("；") }}</el-descriptions-item>
            <el-descriptions-item :span="3" label="制作工艺" label-class-name="desc_label" class-name="desc_content">{{ task.process1?.join("；") }}</el-descriptions-item>
            <el-descriptions-item :span="3" label="奉客形式" label-class-name="desc_label" class-name="desc_content">{{ task.style1?.join("；") }}</el-descriptions-item>
        </el-descriptions>
        <!-- KPI权重设置 -->
        <div class="tit"><SmallTitle tit="KPI权重设置" /></div>
        <div class="table_form">
            <el-table :data="weightData" border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }" show-summary :summary-method="getSummaries" :span-method="mergeRows">
                <el-table-column prop="configCategory" label="大类" width="150" />
                <el-table-column prop="configName" label="统计值（得分）" />
                <el-table-column prop="configValue" label="评分权重" width="150" />
            </el-table>
        </div>
    </div>
</template>

<script setup>
import { computed, inject } from "vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import useEgpmStore from "@/store/egpm";
import { cloneDeep } from "loadsh";

const task = inject("task");
const calcMap = { 1: "菜品名称", 2: "母菜名称" };
const ratioMap = { 1: "同比", 2: "环比" };
const egpmStore = useEgpmStore();
const weightData = computed(() => {
    const list = cloneDeep(task.value?.weightConfig || []);
    return egpmStore.calcSpan(list, "configCategory");
});

// 合计
function getSummaries(param) {
    // 计算configValue的总和
    let total = 0;
    param.data.forEach((item) => {
        total += Number(item.configValue);
    });
    return ["", "总计", total];
}

// 合并
function mergeRows({ row, column }) {
    if (column.label === "大类") {
        return { rowspan: row.rowspan, colspan: row.colspan };
    } else {
        return { rowspan: 1, colspan: 1 };
    }
}
</script>

<style lang="scss" scoped>
.params_container {
    .tit {
        padding-bottom: 12px;
        padding-top: 20px;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 15px;
    }
    .table_form {
        table {
            width: 100%;
            border-collapse: collapse;

            th,
            td {
                border: 1px solid #ccc;
                text-align: center;
                padding: 6px;
                color: #333;

                &.back {
                    background-color: #f2f2f2;
                }

                &.text_red {
                    color: red;
                }
                .el-input {
                    width: 120px;
                }
            }

            th {
                background-color: #f2f2f2;
            }
        }
    }
    :deep(.desc_label) {
        width: 100px;
    }
    :deep(.desc_content) {
        min-width: 200px;
        max-width: 30%;
        word-break: break-all;
        word-wrap: break-word;
    }
}
</style>
